<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showSeq="false"
			:showAdd="$hasAccess('market.activitycommon/add')" addName="新增" @getList="getList" @clearSearch="clearSearch"
			@addItemInTable="addOne">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.name" size="small" clearable placeholder="按名称查询">
				</el-input>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="id" title="活动ID" align="center" />
			<vxe-table-column slot="table-item" field="name" title="名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.city" title="城市" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="cfg.platform" title="生效平台" align="center" min-width="30px">
				<template v-slot="{ row }">
					<span>{{$platform[row.cfg.platform||0]}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="cfg.isvip" title="用户类型" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{$userType[row.cfg.isvip]||"全部"}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="btime" title="有效时间" align="center" min-width="100px">
				<template v-slot="{ row }">
					<span>{{row.btime.substr(0,10)}} - {{row.etime.substr(0,10)}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="状态" align="center">
				<template v-slot="{ row }">
					<span>{{row.status==1?'有效':'无效'}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="update_time" title="编辑时间" align="center" min-width="50px">
				<template v-slot="{ row }">
					<span>{{row.cfg.update_time}}</span>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" title="操作" align="center" width="300px">
				<template v-slot="{ row }">
					<el-button size="small" plain @click="detail(row)">详情</el-button>
					<el-button size="small" plain @click="editOne(row)" v-if="$hasAccess('market.activitycommon/edit')">编辑</el-button>
					<el-button size="small" plain @click="deleteOne(row)">删除</el-button>
				</template>
			</vxe-table-column>
		</Tables>

		<!-- 编辑 -->
		<el-dialog title="编辑/新增" :visible.sync="editDialogShow" :close-on-click-modal="false"
			:close-on-press-escape="false" width="700px">
			<el-form :model="editDialogFormData" :rules="rules" ref="editForm" label-width="100px" class="form">
				<el-form-item label="名称" prop="name">
					<el-input v-model="editDialogFormData.name" style="width: 80%;"></el-input>
				</el-form-item>
				<el-form-item label="开始时间" prop="btime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.btime"
						type="date" value-format="yyyy-MM-dd" placeholder="开启时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="结束时间" prop="etime">
					<el-date-picker class="from-item-width" unlink-panels v-model="editDialogFormData.etime"
						type="date" value-format="yyyy-MM-dd" placeholder="结束时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="归属城市" prop="city">
					<el-select v-model="editDialogFormData.cfg.city" placeholder="请选择" style="width:100%">
						<el-option label="全部" value="全部"></el-option>
						<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>				
				<el-form-item label="生效平台" prop="platform">
					<el-select v-model="editDialogFormData.cfg.platform" placeholder="请选择" style="width:100%">
						<el-option v-for="(item,k) in $platform" :key="k" :label="item" :value="parseInt(k)"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="用户类型" prop="isvip">
					<el-radio-group v-model="editDialogFormData.cfg.isvip">
						<el-radio label="0">全部</el-radio>
						<el-radio v-for="(item,k) of $userType" :key="k" :label="k">{{item}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="参与电站" prop="group_ids">
					<el-select v-model="editDialogFormData.cfg.group_ids" placeholder="请选择场站" multiple style="width:100%">
						<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="多级优惠" prop="cfg">
					<el-button size="small" type="primary" @click="addCfg()">新增门槛</el-button> <span style="color: red;">档位必须从大到小</span>
				</el-form-item>
				<el-form-item :label="'立减门槛'+(idx+1)" v-for="(item,idx) in editDialogFormData.cfg.electric" :key="'ele'+idx">
					大于等于<el-input-number type="number" :min="0" :max="500" :step="1" :precision="0" v-model="editDialogFormData.cfg.electric[idx].value" style="width: 108px;" class="input" size="small"></el-input-number>元<br/>
					<div v-for="(item2,idx2) in editDialogFormData.cfg.electric[idx].money" v-bind:key="'money'+idx+'_'+idx2">
						立减金额<el-input-number type="number" :min="0.1" :max="10" :step="0.1" :precision="1" controls-position="right"
							v-model="editDialogFormData.cfg.electric[idx].money[idx2][0]" style="width: 108px;" class="input" size="small" placeholder="元">
						</el-input-number>元 至
						<el-input-number type="number" :min="0.1" :max="10" :step="0.1" :precision="1" controls-position="right"
							v-model="editDialogFormData.cfg.electric[idx].money[idx2][1]" style="width: 108px;" class="input" size="small" placeholder="元">
						</el-input-number>元 
						概率 <el-input-number type="number" :min="1" :max="100" :step="1" :precision="0" controls-position="right"
							v-model="editDialogFormData.cfg.electric[idx].money[idx2][2]" style="width: 108px;" class="input" size="small" placeholder="%">
						</el-input-number> %
						<el-button v-if="idx2>0" class="del-button" size="small" type="danger" icon="el-icon-delete" @click="onItemDel(idx,idx2)">删除</el-button> 
					</div>
					<el-button size="small" type="primary" @click="onItemAdd(idx)">新增立减金额</el-button>  <el-button v-if="idx>0" size="small" type="danger" icon="el-icon-delete" @click="delCfg(idx)">删除此门槛</el-button>
				</el-form-item>		
				<el-form-item label="是否有效" prop="status">
					<el-switch v-model="editDialogFormData.status" :active-value="1" :inactive-value="0" clearable />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()">保 存
				</el-button>
				<el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
				</el-button>
			</div>
		</el-dialog>
		<!-- 详情 -->
		<el-dialog title="详情" :visible.sync="detailShow" :close-on-click-modal="false" :close-on-press-escape="false" width="600px">
			<el-descriptions title="活动信息" :column="1">
				<el-descriptions-item label="活动名称">{{detailData.name}}</el-descriptions-item>
				<el-descriptions-item label="活动时间">{{detailData.btime.substr(0,10)}} 至 {{detailData.etime.substr(0,10)}}</el-descriptions-item>
				<el-descriptions-item label="归属城市">{{detailData.cfg.city}}</el-descriptions-item>
				<el-descriptions-item label="生效平台">{{$platform[detailData.cfg.platform]}}</el-descriptions-item>
				<el-descriptions-item label="用户类型">{{$userType[detailData.cfg.isvip]||"全部"}}</el-descriptions-item>
				<el-descriptions-item label="状态">{{detailData.status==1?'有效':'无效'}}</el-descriptions-item>
			</el-descriptions>
			<hr/>
			<el-descriptions title="优惠信息" :column="1">
				<template v-for="(item,idx) in detailData.cfg.electric">
					<el-descriptions-item :label="'立减门槛'+(idx+1)">大于等于{{item.value}}</el-descriptions-item>
					<el-descriptions-item label="立减金额">
						<template v-for="(mm,idx2) in item.money">{{idx2>0?' ; ':''}}{{ mm[0] }}-{{ mm[1] }} (概率{{mm[2]}}%) </template>
					</el-descriptions-item>		
				</template>
			</el-descriptions>
			<hr/>
			<el-descriptions title="参与场站" :column="1" :colon="false">
				<template v-for="(item,idx) in detailData.cfg.group_ids">
					<el-descriptions-item label="">{{groupMap[item]}}</el-descriptions-item>		
				</template>
			</el-descriptions>
		</el-dialog>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'

	export default {
		name: 'market-activitycommon-lottery',
		components: {
			Tables,
			upload
		},
		data() {
			return {
				// 表格信息
				tableName: '随机立减',
				isLoading: false,
				totalPage: 0,
				searchFormData: {},
				List: [],
				couponList: [],
				groupList:[], //场站列表
				groupMap:{}, 
				selecttime: {},
				rules: {
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					platform: [{
						required: true,
						message: '请选择生效平台',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("platform" in this.editDialogFormData.cfg)) return callback(new Error('请选择生效平台'));
							callback();
						}
					}],
					isvip: [{
						required: true,
						message: '请选择用户类型',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("isvip" in this.editDialogFormData.cfg)) return callback(new Error('请选择用户类型'));
							callback();
						}
					}],
					city: [{
						required: true,
						message: '请选择区域',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("city" in this.editDialogFormData.cfg)) return callback(new Error('请选择区域'));
							callback();
						}
					}],
					cfg: [{
						required: true,
						message: '门槛必须是从大到小，金额区间不能有交集，一个门槛概率和不能超过100',
						trigger: 'change',
						validator: (rule, value, callback)=>{
							if(!("electric" in this.editDialogFormData.cfg)) return callback(new Error('必须添加门槛信息'));
							let maxEle = Number.MAX_SAFE_INTEGER;
							for(let v of this.editDialogFormData.cfg.electric){
								if(v.value > maxEle){
									return callback(new Error('门槛必须从大到小'));
								}
								maxEle = v.value;
								let pre = 0, mend = Number.MAX_SAFE_INTEGER;
								for(let e of v.money){
									pre += e[2];
									if(mend < e[1]){
										console.log("金额有交集", mend, e[1]);
										return callback(new Error('金额有交集'));
									}
									if(e[1] < e[0]){
										console.log("金额区间有错误", e[1], e[0]);
										return callback(new Error('金额区间有错误'));
									}
									mend = e[0];
								}
								if(pre > 100){
									return callback(new Error('概率和错误'));
								}
							}
							callback();
						}
					}],
					btime: [{
						required: true,
						message: '请输入活动开始时间',
						trigger: 'change'
					}],
					etime: [{
						required: true,
						message: '请输入活动结束时间',
						trigger: 'change'
					}]
				},
				// 编辑
				editDialogShow: false,
				editDialogFormData: {btime:"",etime:"",cfg:{}},
				detailShow: false, //详情对话框是否显示
				detailData: {btime:"",etime:"",cfg:{}},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
			//获取优惠券列表
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100,
			};
			this.$api.Market.GetCouponList(params, {}).then((res)=>{
				for (let d of res.data) {
					if (d.max_money != null) {
						d.coupon_name = d.coupon_name + '(' + d.max_money + '元)';
					}
				}
				this.couponList = res.data;				
			});
			//获取电站列表
			this.getGroups();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					tp: 6,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.ActivityCommon.list(params);
				for(let v of res.data){
					v.cfg = JSON.parse(v.cfg);
				} 
				this.List = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			getGroups(){ //获取电站列表
				const params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
					city: this.editDialogFormData.cfg.citys
				}
				this.$api.Device.GetGroupMinList(params).then((res)=>{
					this.groupList = res;
					for(let v of this.groupList){
						this.groupMap[v.group_id] = v.group_name;
					}
					let gids = [];
					for(let gid of this.editDialogFormData.cfg.group_ids){
						for(let gv of this.groupList){
							if(gv.group_id == gid){
								gids.push(gid);
							}
						}
					}
					this.editDialogFormData.cfg.group_ids = gids;
				});
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 重置form
			resetFormData() {
				this.editDialogFormData = {btime:"",etime:"",status:1,cfg:{electric:[{"value":10,"money":[[0.5,2,100]]}]}}
				if (this.$refs.editForm) {
					this.$refs.editForm.resetFields()
				}
			},
			// 新增
			addOne() {
				this.resetFormData()
				this.editDialogShow = true
			},
			// 编辑
			async editOne(row) {
				this.resetFormData();
				//row.status = row.status.toString();
				this.editDialogFormData = Object.assign({}, row);
				delete this.editDialogFormData._XID;
				this.editDialogShow = true
			},
			// 删除
			deleteOne(row) {
				this.$confirm('确定要删除该记录吗', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						id: row.id
					}
					await this.$api.Market.ActivityCommon.del(params)
					this.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 关闭弹窗
			closeDialog() {
				this.editDialogShow = false
			},
			// 编辑保存
			saveEdit() {
				this.$refs.editForm.validate(async valid => {
					if (valid) {
						let params = {
							token: this.$store.state.user.token,
							form: Object.assign({"tp":6}, this.editDialogFormData)
						}
						params.form.cfg.update_time = this.$common.DateTimeStr();
						params.form.cfg = JSON.stringify(params.form.cfg);
						params.form = JSON.stringify(params.form);
						if(this.editDialogFormData.id > 0){
							await this.$api.Market.ActivityCommon.edit(params);
						}else{
							await this.$api.Market.ActivityCommon.add(params);
						}
						this.$notify({
							title: '成功',
							message: '操作成功',
							type: 'success'
						})
						this.closeDialog()
						this.$refs.xTable.refreshTable()
					}
				})
			},
			// 显示配置详情
			detail(row) {
				this.detailShow = true;
				this.detailData = row;
			},			
			addCfg(){ //添加挡位配置
				this.editDialogFormData.cfg.electric.push({value:10,money:[[0.5,1,0]]});
			},
			delCfg(idx){ //添加挡位配置
				this.editDialogFormData.cfg.electric.splice(idx, 1);
			},
			onItemDel(idx,idx2){ //删除概率配置
				this.editDialogFormData.cfg.electric[idx].money.splice(idx2, 1);
			},
			onItemAdd(idx){ //添加概率配置
				this.editDialogFormData.cfg.electric[idx].money.push([0.5,1,0]);
			},
		}
	}
</script>

<style lang="scss" scoped>
</style>
